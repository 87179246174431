// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  userId:null,
  username: null,
  token: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser(state, action) {
      state.isLoggedIn = true;
      state.username = action.payload.username;
      state.userId = action.payload.userId;
      state.token = action.payload.token;
    },
    logoutUser(state) {
      state.isLoggedIn = false;
      state.username = null;
      state.userId =null;
      state.token = null;
    }
  }
});

export const { loginUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;