import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Badge, Menu, MenuItem, Avatar, Box, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import './Header.css';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../store/slices/userSlice'; // 假设有logout的action

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartItemsCount = useSelector((state) => state.cart.items.length);
  const { isLoggedIn, username } = useSelector((state) => state.user);

  const handleViewCart = () => {
    navigate('/cart');
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleUserMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    // 可选：跳转到登录页或主页
    navigate('/login');
  };

  return (
    <AppBar position="static" className="header-appbar" sx={{ backgroundColor:'#1976d2', paddingX:2 }}>
      <Toolbar className="header-toolbar" sx={{ display:'flex', justifyContent:'space-between' }}>
        {/* 左侧：品牌名 */}
        <Box sx={{ display:'flex', alignItems:'center', gap:2 }}>
          <Typography variant="h6" className="header-title" sx={{ fontWeight:'bold' }}>
            <Link to="/" style={{ color:'#fff', textDecoration:'none' }}>
              ShoeStore
            </Link>
          </Typography>

          {/* 导航链接 */}
          <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
            <Link to="/gpt-recommend" style={{ color:'#fff', textDecoration:'none' }}>GPT Recommend</Link>
          </Typography>
          <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
            <Link to="/ai-generate" style={{ color:'#fff', textDecoration:'none' }}>AI Generate</Link>
          </Typography>
        </Box>

        {/* 右侧：用户状态和购物车 */}
        <Box sx={{ display:'flex', alignItems:'center', gap:2 }}>
          {/* 如果已登录，显示用户名和头像、下拉菜单；未登录显示 注册 和 登录 */}
          {isLoggedIn ? (
            <>
              {/* 用户菜单 */}
              <IconButton onClick={handleUserMenuOpen}>
                <Avatar sx={{ bgcolor:'#ffffff', color:'#1976d2' }}>{username ? username.charAt(0).toUpperCase() : '?'}</Avatar>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleUserMenuClose}
                anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                transformOrigin={{ vertical:'top', horizontal:'right' }}
              >
                <MenuItem disabled>Hello, {username}</MenuItem>
                <MenuItem onClick={handleUserMenuClose}><Link to="/profile" style={{textDecoration:'none', color:'inherit'}}>My Profile</Link></MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Button variant="text" sx={{ color:'#fff' }} component={Link} to="/login">Login</Button>
              <Button variant="outlined" sx={{ color:'#fff', borderColor:'#fff' }} component={Link} to="/RegisterVerifyPage">Register</Button>
            </>
          )}

          {/* 收藏和购物车图标 */}
          <IconButton size="large" color="inherit">
            <Badge badgeContent={4} color="error">
              <FavoriteIcon/>
            </Badge>
          </IconButton>
          <IconButton size="large" color="inherit" onClick={handleViewCart}>
            <Badge badgeContent={cartItemsCount} color="error">
              <ShoppingCartIcon/>
            </Badge>
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}