import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './slices/cartSlice';
import shoesReducer from './slices/shoesSlice';
import userReducer from './slices/userSlice';

/**
 * Configure and export the Redux store.
 */
export const store = configureStore({
  reducer: {
    cart: cartReducer,
    shoes: shoesReducer,
    user: userReducer
  }
});