// LoginPage.jsx
import React, { useState } from 'react';
import { Box, Typography, Container, TextField, Button } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './LoginPage.css';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, logoutUser } from '../store/slices/userSlice';
import { useNavigate, Link } from 'react-router-dom';

export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggedIn, username } = useSelector((state) => state.user);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handleLogin = async () => {
    setErrorMsg('');
    if (!email.trim() || !password.trim()) {
      setErrorMsg('Email and Password are required.');
      return;
    }

    try {
      const res = await fetch('http://3.107.234.91:5000/api/auth/login', {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({email, password})
      });
      const result = await res.json();
      console.log('Login response:', result);
      if (!res.ok) {
        setErrorMsg(result.error || 'Unknown error');
      } else {
        // 登录成功，从返回结果中提取token和userId
        sessionStorage.setItem('token', result.token);
        sessionStorage.setItem('userId', result.userId);
        dispatch(loginUser({username: result.username}));
        navigate('/');
      }
    } catch (err) {
      setErrorMsg('Network error, please try again.');
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    dispatch(logoutUser());
  };

  return (
    <Box className="login-container">
      <Header />

      <Container maxWidth="sm" sx={{ py:4 }}>
        {!isLoggedIn ? (
          <>
            <Typography variant="h5" sx={{ mb:2, fontWeight:'bold' }}>
              Login
            </Typography>
            {errorMsg && (
              <Typography variant="body2" color="error" sx={{ mb:2 }}>
                {errorMsg}
              </Typography>
            )}
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              sx={{ mb:2 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              sx={{ mb:2 }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button variant="contained" onClick={handleLogin} fullWidth>
              Login
            </Button>
            <Typography variant="body2" sx={{ mt:2 }}>
              Don't have an account? <Link to="/RegisterVerifyPage" style={{textDecoration:'none'}}>Register here</Link>
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h5" sx={{ mb:2, fontWeight:'bold' }}>
              Already Logged In
            </Typography>
            <Typography variant="body1" sx={{ mb:2 }}>
              Hello, {username}. You are already logged in.
            </Typography>
            <Box sx={{ display:'flex', gap:2 }}>
              <Button variant="contained" color="error" onClick={handleLogout}>Logout</Button>
              <Button variant="contained" onClick={() => navigate('/')}>Go to Home</Button>
            </Box>
          </>
        )}
      </Container>

      <Footer />
    </Box>
  );
}