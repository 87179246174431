import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  CircularProgress, 
  Alert, 
  Snackbar,
  Paper
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Container = styled('div')({
  maxWidth: 400,
  margin: 'auto',
  marginTop: '50px',
  padding: '20px'
});

export default function RegisterAndVerifyPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [code, setCode] = useState('');

  const [isRegistered, setIsRegistered] = useState(false); // 是否已经注册成功
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [message, setMessage] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  // 简单密码复杂度检查：长度>=8，包含字母和数字
  const validatePassword = (pwd) => {
    if (pwd.length < 8) return false;
    const hasLetter = /[A-Za-z]/.test(pwd);
    const hasDigit = /[0-9]/.test(pwd);
    return hasLetter && hasDigit;
  };

  const handleRegister = async () => {
    setLoading(true);
    setErrorMsg('');
    setMessage('');

    if (!validateEmail(email)) {
      setErrorMsg('Please enter a valid email address.');
      setLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      setErrorMsg('Password must be at least 8 characters and contain both letters and numbers.');
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setErrorMsg('Passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      const res = await fetch('http://3.107.234.91:5000/api/auth/register', {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({ email, password })
      });
      const result = await res.json();

      if (!res.ok) {
        const errMsg = typeof result.error === 'string' ? result.error : JSON.stringify(result.error);
        setErrorMsg(errMsg || 'Unknown error');
      } else {
        const msg = typeof result.message === 'string' ? result.message : JSON.stringify(result.message);
        setMessage(msg);
        // 注册成功
        setIsRegistered(true);
      }
    } catch (err) {
      setErrorMsg('Network error, please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async () => {
    if (!isRegistered) {
      setErrorMsg('Please register first to get a verification code.');
      return;
    }

    setLoading(true);
    setErrorMsg('');
    setMessage('');

    if (!validateEmail(email)) {
      setErrorMsg('Email invalid.');
      setLoading(false);
      return;
    }

    if (!code.trim()) {
      setErrorMsg('Please enter the verification code.');
      setLoading(false);
      return;
    }

    try {
      const res = await fetch('http://3.107.234.91:5000/api/auth/verify', {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({ email, code })
      });
      const result = await res.json();
      if (!res.ok) {
        const errMsg = typeof result.error === 'string' ? result.error : JSON.stringify(result.error);
        setErrorMsg(errMsg || 'Unknown error');
      } else {
        const msg = typeof result.message === 'string' ? result.message : JSON.stringify(result.message);
        setMessage(msg);
        // 验证成功后提示并3秒后跳转登录页面
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    } catch (err) {
      setErrorMsg('Network error, please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Paper elevation={3} sx={{ p:3 }}>
          <Typography variant="h4" sx={{ mb:3, textAlign:'center', fontWeight:'bold' }}>
            Register & Verify
          </Typography>

          {errorMsg && (
            <Alert severity="error" sx={{ mb:2 }}>
              {errorMsg}
            </Alert>
          )}
          {message && !errorMsg && (
            <Alert severity="success" sx={{ mb:2 }}>
              {message}
            </Alert>
          )}

          <Box sx={{ mb:3 }}>
            <Typography variant="h6" sx={{ mb:1 }}>
              Register
            </Typography>
            <TextField 
              label="Email" 
              placeholder="Enter your email"
              variant="outlined" 
              fullWidth 
              sx={{ mb:2 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isRegistered}
            />
            <TextField 
              label="Password" 
              placeholder="At least 8 chars, letters & digits"
              type="password" 
              variant="outlined" 
              fullWidth 
              sx={{ mb:2 }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isRegistered}
            />
            <TextField 
              label="Confirm Password"
              placeholder="Re-enter password"
              type="password" 
              variant="outlined" 
              fullWidth 
              sx={{ mb:2 }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={isRegistered}
            />

            <Button 
              variant="contained" 
              fullWidth
              onClick={handleRegister}
              disabled={loading || isRegistered}
            >
              {loading && !isRegistered ? <CircularProgress size={24} color="inherit" /> : 'Register'}
            </Button>
          </Box>

          <Box sx={{ mb:3 }}>
            <Typography variant="h6" sx={{ mb:1 }}>
              Verification
            </Typography>
            <Typography variant="body2" sx={{ mb:1 }}>
              {isRegistered 
                ? `A verification code was sent to your email (${email}). Please enter it below:`
                : 'Please register first to receive a verification code.'}
            </Typography>
            <TextField 
              label="Verification Code" 
              placeholder="Enter the code from your email"
              variant="outlined" 
              fullWidth 
              sx={{ mb:2 }}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              disabled={!isRegistered || loading}
            />

            <Button 
              variant="contained" 
              fullWidth
              onClick={handleVerify}
              disabled={!isRegistered || loading}
            >
              {loading && isRegistered ? <CircularProgress size={24} color="inherit" /> : 'Verify'}
            </Button>
          </Box>
        </Paper>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={()=>setOpenSnackbar(false)}
          message="Verification successful! Redirecting to login..."
        />
      </Container>
      <Footer />
    </>
  );
}