import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, Typography, Container, TextField, Button, CircularProgress, Paper, Grid, Rating
} from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function AIGeneratePage() {
  const [color, setColor] = useState('');
  const [size, setSize] = useState('');
  const [style, setStyle] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const [imageUrl, setImageUrl] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [currentRating, setCurrentRating] = useState(null);

  const [history, setHistory] = useState([]);
  const [basicRemaining, setBasicRemaining] = useState(null);
  const [advancedRemaining, setAdvancedRemaining] = useState(null);
// 使用sessionStorage存储token
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    // 未登录则跳转登录页
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  const validateInputs = () => {
    if (!color.trim() || !size.trim() || !style.trim()) {
      setError('Please provide color, size, and style.');
      return false;
    }
    return true;
  };

   // 使用useCallback，使fetchUsage在token不变时引用稳定
   const fetchUsage = useCallback(async () => {
    if (!token) return;
    try {
      const res = await axios.get('http://3.107.234.91:5000/api/user/usage', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setBasicRemaining(res.data.basic_remaining);
      setAdvancedRemaining(res.data.advanced_remaining);
    } catch (err) {
      console.error("Failed to fetch usage:", err);
      setError("Failed to fetch usage data.");
    }
  }, [token]); // 依赖于token，因为需要在token改变时重新定义函数


  // 同理对fetchGallery使用useCallback
  const fetchGallery = useCallback(async () => {
    if (!token) return;
    try {
      const res = await axios.get('http://3.107.234.91:5000/api/user/gallery', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setHistory(res.data.map(item => ({
        id: item.id,
        image_url: item.image_url,
        color: item.color,
        size: item.size,
        style: item.style,
        type: item.type,
        rating: item.rating,
        createdAt: new Date(item.createdAt).toLocaleString()
      })));
    } catch (err) {
      console.error("Failed to fetch gallery:", err);
      setError("Failed to fetch gallery data.");
    }
  }, [token]); // 同样依赖于token

  useEffect(() => {
    if (token) {
      fetchUsage();
      fetchGallery();
    }
  }, [token, fetchUsage, fetchGallery]);
  // 现在fetchUsage和fetchGallery是useCallback定义的，依赖稳定，不会在每次渲染改变引用

  const handleGenerate = async (endpoint, type) => {
    setError(null);
    setImageUrl(null);
    setCurrentRating(null);

    if (!validateInputs()) return;

    setLoading(true);
    try {
      const response = await axios.post(endpoint, {
        color: color,
        size: size,
        style: style
      });
      const url = response.data.image_url;
      setImageUrl(url);
      setImageType(type);
    } catch (err) {
      console.error('Error calling generate-image API:', err);
      setError('Failed to generate image. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleBasicGenerate = () => {
    handleGenerate('http://3.107.234.91:5002/generate-image', 'Basic');
  };

  const handleAdvancedGenerate = () => {
    handleGenerate('http://3.107.234.91:5003/generate-image', 'Advanced');
  };

  const handleRatingChange = async (newValue) => {
    setCurrentRating(newValue);
  
    if (imageUrl && imageType && newValue) {
      const createdAt = new Date().toLocaleString(); 
      const newRecord = {
        id: Date.now(),
        image_url: imageUrl,
        color,
        size,
        style,
        type: imageType,
        createdAt,
        rating: newValue
      };
  
      const newHistory = [newRecord, ...history]; 
      setHistory(newHistory);
  
      if (!token) {
        console.warn("No token found. Please login first.");
        setError("No token found. Please login first.");
        return;
      }
  
      try {
        await axios.post('http://3.107.234.91:5000/api/gallery', {
          ImageUrl: imageUrl,
          Color: color,
          Size: size,
          Style: style,
          GenType: imageType,
          Rating: newValue
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // 保存成功后再刷新usage
        fetchUsage();
      } catch (err) {
        console.error("Failed to save gallery image to DB:", err);
        setError("Failed to save gallery image to DB. " + (err.response?.data?.error || ''));
      }
    }
  };

  return (
    <Box sx={{ backgroundColor:'#f5f5f5', minHeight:'100vh' }}>
      <Header />
      <Container maxWidth="md" sx={{ py:4 }}>
        <Typography variant="h5" sx={{ fontWeight:'bold', mb:2 }}>
          AI Generate Image
        </Typography>
        <Typography variant="body1" sx={{ mb:3 }}>
          Provide the color, size, and style to generate an image. 
          Choose "Basic" or "Advanced" for different generation methods.
        </Typography>

        {error && (
          <Typography variant="body1" color="error" sx={{ mb:2 }}>
            {error}
          </Typography>
        )}

        {loading && !error && (
          <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', minHeight:'10vh', mb:2 }}>
            <CircularProgress />
          </Box>
        )}

        <Paper elevation={3} sx={{ p:3, borderRadius:2, mb:4 }}>
          <Grid container spacing={3} alignItems="flex-start">
            {/* Row for Color & Basic */}
            <Grid item xs={6}>
              <TextField
                placeholder="Color (e.g. white, rainbow)"
                variant="outlined"
                value={color}
                onChange={(e) => setColor(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sx={{ display:'flex', flexDirection:'column', alignItems:'flex-end' }}>
              {token && (
                <Typography variant="body2" sx={{ mb:1, textAlign:'right', color:'#333' }}>
                  Basic Remaining: {basicRemaining !== null ? basicRemaining : '...'}
                </Typography>
              )}
              <Button 
                variant="contained" 
                onClick={handleBasicGenerate} 
                disabled={loading || (basicRemaining !== null && basicRemaining <= 0)}
                sx={{ maxWidth:200 }}
              >
                {loading ? 'Generating...' : 'Basic AI'}
              </Button>
            </Grid>

            {/* Row for Size */}
            <Grid item xs={6}>
              <TextField
                placeholder="Size (e.g. US10 or large)"
                variant="outlined"
                value={size}
                onChange={(e) => setSize(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              {/* 留空 */}
            </Grid>

            {/* Row for Style & Advanced */}
            <Grid item xs={6}>
              <TextField
                placeholder="Style (e.g. running, basketball, tennis)"
                variant="outlined"
                value={style}
                onChange={(e) => setStyle(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sx={{ display:'flex', flexDirection:'column', alignItems:'flex-end' }}>
              {token && (
                <Typography variant="body2" sx={{ mb:1, textAlign:'right', color:'#333' }}>
                  Advanced Remaining: {advancedRemaining !== null ? advancedRemaining : '...'}
                </Typography>
              )}
              <Button 
                variant="contained" 
                onClick={handleAdvancedGenerate} 
                disabled={loading || (advancedRemaining !== null && advancedRemaining <= 0)}
                sx={{ maxWidth:200 }}
              >
                {loading ? 'Generating...' : 'Advanced AI'}
              </Button>
            </Grid>
          </Grid>
        </Paper>

        {/* 结果显示区域 */}
        <Paper elevation={2} sx={{ p:2, borderRadius:2, mb:4 }}>
          <Typography variant="h6" sx={{ fontWeight:'bold', mb:1 }}>
            Generated Image:
          </Typography>
          <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', minHeight:'300px', backgroundColor:'#ddd', borderRadius:'8px' }}>
            {imageUrl ? (
              <img src={imageUrl} alt="Generated" style={{ maxWidth:'100%', height:'auto', borderRadius:'8px' }} />
            ) : (
              <Typography variant="body1" sx={{ color:'#555' }}>
                Image will appear here…
              </Typography>
            )}
          </Box>

          {imageUrl && !loading && (
            <Box sx={{ mt:2, display:'flex', flexDirection:'column', alignItems:'flex-start' }}>
              <Typography variant="body1" sx={{ mb:1 }}>
                Rate this image (you can rate half stars):
              </Typography>
              <Rating
                name="image-rating"
                value={currentRating}
                precision={0.5}
                onChange={(event, newValue) => {
                  handleRatingChange(newValue);
                }}
              />
            </Box>
          )}
        </Paper>

        {/* Gallery 展示历史记录, 最新的在最前面 */}
        <Paper elevation={1} sx={{ p:2, borderRadius:2 }}>
          <Typography variant="h6" sx={{ fontWeight:'bold', mb:2 }}>
            Gallery
          </Typography>
          {history.length === 0 ? (
            <Typography variant="body1">No images generated yet.</Typography>
          ) : (
            <Grid container spacing={2}>
              {history.map((item) => (
                <Grid item xs={12} md={6} key={item.id}>
                  <Paper elevation={2} sx={{ p:2, borderRadius:2 }}>
                    <Box sx={{ mb:1, display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#eee', borderRadius:'8px' }}>
                      <img src={item.image_url} alt="Generated history" style={{ maxWidth:'100%', height:'auto', borderRadius:'8px' }} />
                    </Box>
                    <Typography variant="body2"><strong>Generated at:</strong> {item.createdAt}</Typography>
                    <Typography variant="body2"><strong>Type:</strong> {item.type}</Typography>
                    <Typography variant="body2"><strong>Color:</strong> {item.color}</Typography>
                    <Typography variant="body2"><strong>Size:</strong> {item.size}</Typography>
                    <Typography variant="body2"><strong>Style:</strong> {item.style}</Typography>
                    <Typography variant="body2" sx={{ mt:1 }}>
                      <strong>Rating:</strong> {item.rating ? `${item.rating}/5` : 'Not rated'}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        </Paper>
      </Container>
      <Footer />
    </Box>
  );
}