import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './CartPage.css';
import { useSelector, useDispatch } from 'react-redux';
import { clearCart } from '../store/slices/cartSlice';

/**
 * CartPage:
 * Displays the items in the cart. Allows clearing the cart.
 */
export default function CartPage() {
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  return (
    <Box className="cart-container">
      <Header />

      <Container maxWidth="lg" sx={{ py:4 }}>
        <Typography variant="h5" sx={{ mb:2, fontWeight:'bold' }}>
          Your Cart
        </Typography>

        {cartItems.length === 0 ? (
          <Typography variant="body1">Your cart is empty.</Typography>
        ) : (
          <>
            {cartItems.map((item, idx) => (
              <Typography key={idx} variant="body2" sx={{ mb:1 }}>
                {item.title} - {item.price}
              </Typography>
            ))}
            <Button variant="contained" color="error" onClick={handleClearCart}>
              Clear Cart
            </Button>
          </>
        )}
      </Container>

      <Footer />
    </Box>
  );
}