// ProfilePage.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Avatar,
  TextField,
  Button,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/system';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const AvatarWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  marginBottom: theme.spacing(2),
}));

const CameraIconWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  backgroundColor: theme.palette.background.paper,
  borderRadius: '50%',
  boxShadow: theme.shadows[2],
}));

export default function ProfilePage() {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    avatarUrl: '',
    membershipLevel: 'common', // common, vip, etc.
    gptRemaining: 0,
    aiRemaining: 0,
  });
  const [avatarFile, setAvatarFile] = useState(null);
  const [editMode, setEditMode] = useState(false);

  // 修改密码对话框状态
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordLoading, setPasswordLoading] = useState(false);

  useEffect(() => {
    // 模拟从后端获取用户数据
    async function fetchProfile() {
      setLoading(true);
      try {
        const res = await fetch('/api/profile', { credentials: 'include' });
        const data = await res.json();
        setUserData(data);
      } catch (err) {
        console.error('Failed to fetch profile:', err);
      } finally {
        setLoading(false);
      }
    }
    fetchProfile();
  }, []);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAvatarFile(file);
      // 显示本地预览
      const reader = new FileReader();
      reader.onload = (e) => {
        setUserData(prev => ({ ...prev, avatarUrl: e.target.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      // TODO: 调用后端API更新用户数据(包括上传头像)
      // 使用FormData上传头像 + 其他字段
      const formData = new FormData();
      formData.append('name', userData.name);
      formData.append('phone', userData.phone);
      // 如果修改了头像文件
      if (avatarFile) {
        formData.append('avatar', avatarFile);
      }

      const res = await fetch('/api/profile', {
        method: 'PUT',
        credentials: 'include',
        body: formData,
      });
      if (!res.ok) {
        const errData = await res.json();
        alert(errData.error || 'Update failed');
      } else {
        alert('Profile updated successfully');
        setEditMode(false);
        setAvatarFile(null);
      }
    } catch (err) {
      console.error('Failed to save profile:', err);
      alert('Update failed, please try again later.');
    } finally {
      setSaving(false);
    }
  };

  const openPasswordDialog = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setPasswordDialogOpen(true);
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      alert('New password and confirm password do not match.');
      return;
    }
    setPasswordLoading(true);
    try {
      // 调用后端API更新密码
      const res = await fetch('/api/profile/password', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentPassword,
          newPassword
        })
      });
      const result = await res.json();
      if (!res.ok) {
        alert(result.error || 'Update password failed');
      } else {
        alert('Password updated successfully');
        setPasswordDialogOpen(false);
      }
    } catch (err) {
      console.error('Failed to update password:', err);
      alert('Network error, please try again later.');
    } finally {
      setPasswordLoading(false);
    }
  };

  if (loading) {
    return (
      <>
        <Header />
        <Container sx={{ py:4, textAlign:'center' }}>
          <CircularProgress />
        </Container>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <Container maxWidth="md" sx={{ py:4 }}>
        <Typography variant="h4" sx={{ mb:2, fontWeight:'bold' }}>My Profile</Typography>
        
        <Card sx={{ mb:4 }}>
          <CardHeader
            title="Personal Information"
            action={!editMode && (
              <IconButton onClick={() => setEditMode(true)}>
                <EditIcon />
              </IconButton>
            )}
          />
          <Divider />
          <CardContent>
            <Box sx={{ display:'flex', flexDirection:{xs:'column', md:'row'}, gap:4, alignItems:'flex-start' }}>
              <Box sx={{textAlign:'center'}}>
                <AvatarWrapper>
                  <Avatar
                    src={userData.avatarUrl}
                    sx={{ width:120, height:120 }}
                  />
                  {editMode && (
                    <CameraIconWrapper>
                      <IconButton color="primary" component="label" size="small">
                        <PhotoCamera fontSize="small" />
                        <input hidden accept="image/*" type="file" onChange={handleAvatarChange} />
                      </IconButton>
                    </CameraIconWrapper>
                  )}
                </AvatarWrapper>
                <Typography variant="body2">Change Avatar</Typography>
              </Box>

              <Box sx={{ flex:1, display:'flex', flexDirection:'column', gap:2 }}>
                <TextField
                  label="Email"
                  value={userData.email}
                  fullWidth
                  disabled
                  helperText="Email cannot be changed"
                />
                <TextField
                  label="Name"
                  value={userData.name}
                  fullWidth
                  onChange={(e)=>setUserData(prev=>({...prev, name:e.target.value}))}
                  disabled={!editMode}
                  placeholder="Enter your full name"
                />
                <TextField
                  label="Phone"
                  value={userData.phone}
                  fullWidth
                  onChange={(e)=>setUserData(prev=>({...prev, phone:e.target.value}))}
                  disabled={!editMode}
                  placeholder="Enter your phone number"
                />
              </Box>
            </Box>
            {editMode && (
              <Box sx={{mt:2}}>
                <Button variant="contained" color="primary" onClick={handleSave} disabled={saving}>
                  {saving ? 'Saving...' : 'Save Changes'}
                </Button>
                <Button variant="text" color="secondary" onClick={()=>setEditMode(false)} sx={{ml:2}}>
                  Cancel
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>

        <Card sx={{ mb:4 }}>
          <CardHeader
            title="Account Security"
            action={<IconButton onClick={openPasswordDialog}><LockIcon /></IconButton>}
          />
          <Divider />
          <CardContent>
            <Typography variant="body2">
              Update your password regularly to keep your account secure.
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ mb:4 }}>
          <CardHeader title="Membership & Usage" />
          <Divider />
          <CardContent>
            <Typography variant="subtitle1" sx={{ mb:1 }}>Membership Level: {userData.membershipLevel}</Typography>
            <Typography variant="body2" sx={{ mb:1 }}>GPT Recommendations Remaining: {userData.gptRemaining}</Typography>
            <Typography variant="body2" sx={{ mb:2 }}>AI Generation Remaining: {userData.aiRemaining}</Typography>
            <Typography variant="caption" display="block" sx={{mb:2}}>
              Upgrade your membership to enjoy more benefits.
            </Typography>
            <Button variant="outlined">Upgrade Membership</Button>
          </CardContent>
        </Card>

        <Card sx={{ mb:4 }}>
          <CardHeader title="Orders & Addresses" />
          <Divider />
          <CardContent sx={{ display:'flex', flexDirection:'column', gap:2 }}>
            <Button component={Link} to="/orders" variant="outlined">View Order History</Button>
            <Button component={Link} to="/addresses" variant="outlined">Manage Shipping Addresses</Button>
          </CardContent>
        </Card>

      </Container>

      <Footer />

      {/* Change Password Dialog */}
      <Dialog open={passwordDialogOpen} onClose={()=>setPasswordDialogOpen(false)}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent sx={{ display:'flex', flexDirection:'column', gap:2 }}>
          <TextField
            label="Current Password"
            type="password"
            fullWidth
            value={currentPassword}
            onChange={(e)=>setCurrentPassword(e.target.value)}
            placeholder="Enter current password"
          />
          <TextField
            label="New Password"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e)=>setNewPassword(e.target.value)}
            placeholder="At least 8 characters, including numbers & letters"
          />
          <TextField
            label="Confirm New Password"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={(e)=>setConfirmPassword(e.target.value)}
            placeholder="Re-enter new password"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setPasswordDialogOpen(false)}>Cancel</Button>
          <Button onClick={handlePasswordChange} disabled={passwordLoading}>
            {passwordLoading ? 'Updating...' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}