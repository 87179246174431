import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Divider, Container, Button, 
  CircularProgress, Select, MenuItem, FormControl, 
  Card, Grid, ImageList, ImageListItem 
} from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './GptRecommendPage.css';
import { useSelector, useDispatch } from 'react-redux';
import { setGptRecommendations } from '../store/slices/shoesSlice'; // 根据实际路径修改
import axios from 'axios';
import { mapShoeData } from '../utils';

export default function GptRecommendPage() {
  const dispatch = useDispatch();
  const gptRecommendations = useSelector((state) => state.shoes.gptRecommendations);

  const [gptGender, setGptGender] = useState('male');
  const [gptType, setGptType] = useState('running');
  const [gptLoading, setGptLoading] = useState(false);
  const [maleDbShoes, setMaleDbShoes] = useState([]);
  const [loadingMaleDb, setLoadingMaleDb] = useState(false);

  const [femaleDbShoes, setFemaleDbShoes] = useState([]);
  const [loadingFemaleDb, setLoadingFemaleDb] = useState(false);

  const [error, setError] = useState(null);

  const handleGptSearch = async () => {
    setError(null);
    setGptLoading(true);
    try {
      const response = await axios.get('http://3.107.234.91:5000/api/gpt/recommend', {
        params: { gender: gptGender, type: gptType }
      });
      dispatch(setGptRecommendations(response.data));
    } catch (err) {
      setError('Failed to fetch GPT recommendations. Please try again later.');
    } finally {
      setGptLoading(false);
    }
  };

  useEffect(() => {
    const fetchMaleShoesFromDb = async () => {
      if (gptRecommendations?.gptRecommendedShoes?.maleShoes?.length > 0) {
        setError(null);
        setLoadingMaleDb(true);
        try {
          const ShoeNames = gptRecommendations.gptRecommendedShoes.maleShoes.join(',');
          const res = await axios.get('http://3.107.234.91:5000/api/shoes', {
            params: {
              names: ShoeNames,
              gender: 'male'
            }
          });
          const shoesData = Array.isArray(res.data.shoes) ? res.data.shoes : [];
          setMaleDbShoes(shoesData);
        } catch (err) {
          setError('Failed to load male shoes from database. Please check network or backend.');
        } finally {
          setLoadingMaleDb(false);
        }
      } else {
        setMaleDbShoes([]);
      }
    };
    if (gptRecommendations) {
      fetchMaleShoesFromDb();
    }
  }, [gptRecommendations]);

  useEffect(() => {
    const fetchFemaleShoesFromDb = async () => {
      if (gptRecommendations?.gptRecommendedShoes?.femaleShoes?.length > 0) {
        setError(null);
        setLoadingFemaleDb(true);
        try {
          const ShoeNames = gptRecommendations.gptRecommendedShoes.femaleShoes.join(',');
          const res = await axios.get('http://3.107.234.91:5000/api/shoes', {
            params: {
              names: ShoeNames,
              gender: 'female'
            }
          });
          const shoesData = Array.isArray(res.data.shoes) ? res.data.shoes : [];
          setFemaleDbShoes(shoesData);
        } catch (err) {
          setError('Failed to load female shoes from database. Please check network or backend.');
        } finally {
          setLoadingFemaleDb(false);
        }
      } else {
        setFemaleDbShoes([]);
      }
    };
    if (gptRecommendations) {
      fetchFemaleShoesFromDb();
    }
  }, [gptRecommendations]);

  const maleItemData = mapShoeData(maleDbShoes || []);
  const femaleItemData = mapShoeData(femaleDbShoes || []);

  const renderDbShoesSection = (loading, itemData, noDataText) => {
    if (loading) {
      return (
        <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', minHeight:'20vh' }}>
          <CircularProgress/>
        </Box>
      );
    }

    if (!error && itemData.length > 0) {
      return (
        <ImageList 
          cols={3} 
          gap={20}
          sx={{
            '@media (max-width:600px)': {
              cols: 2
            }
          }}
        >
          {itemData.map((item) => (
            <ImageListItem
              key={item.id}
              style={{
                borderRadius: '8px',
                boxShadow: '0px 3px 8px rgba(0,0,0,0.1)',
                overflow: 'hidden',
                cursor: 'pointer',
                transition: 'transform 0.2s',
                width: '250px',
                height: '350px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Box sx={{
                width: '100%',
                height: '60%',
                overflow: 'hidden'
              }}>
                <img
                  src={item.img}
                  alt={item.title}
                  loading="lazy"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
              </Box>
              <Box sx={{
                p: 2,
                width: '100%',
                height: '40%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start'
              }}>
                <Typography variant="subtitle1" sx={{ fontWeight:'bold', fontSize:'1rem', lineHeight:1.2 }}>
                  {item.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ fontSize:'0.9rem', lineHeight:1.2 }}>
                  {item.author}
                </Typography>
                {item.status === 0 ? (
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize:'0.9rem', lineHeight:1.2 }}>
                    Coming Soon
                  </Typography>
                ) : (
                  <>
                    <Typography variant="h6" sx={{ my:1, fontWeight:'bold', fontSize:'1.1rem', lineHeight:1.2 }}>
                      {item.price}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize:'0.9rem', lineHeight:1.2 }}>
                      {item.rating}
                    </Typography>
                  </>
                )}
              </Box>
            </ImageListItem>
          ))}
        </ImageList>
      );
    }

    return <Typography variant="body1" color="text.secondary">{noDataText}</Typography>;
  };

  return (
    <Box className="gpt-container">
      <Header />
      <Container maxWidth="lg" sx={{ py:4 }}>
        
        <Typography variant="h5" sx={{ mb:2, fontWeight:'bold' }}>
          GPT Recommend
        </Typography>
        
        <Box sx={{ display:'flex', gap:2, mb:2, alignItems:'center' }}>
          <FormControl size="small">
            <Select value={gptGender} onChange={(e) => setGptGender(e.target.value)}>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small">
            <Select value={gptType} onChange={(e) => setGptType(e.target.value)}>
              <MenuItem value="running">Running</MenuItem>
              <MenuItem value="basketball">Basketball</MenuItem>
              <MenuItem value="tennis">Tennis</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" onClick={handleGptSearch} disabled={gptLoading}>
            {gptLoading ? 'Loading...' : 'Search with GPT'}
          </Button>
        </Box>

        {error && (
          <Typography variant="body1" color="error" sx={{ mb:2 }}>
            {error}
          </Typography>
        )}

        {gptLoading && !error && (
          <Typography variant="body1" sx={{ mb:2 }}>
            Please wait, fetching GPT recommendations...
          </Typography>
        )}

        {gptRecommendations && !gptLoading && !error && (
          <>
            <Divider sx={{ my:4 }} />
            <Typography variant="h5" sx={{ mb:2, fontWeight:'bold' }}>
              GPT Recommendations Result
            </Typography>

            <Grid container spacing={2} sx={{ mb:3 }}>
              <Grid item xs={12} md={6}>
                <Card variant="outlined" sx={{ borderRadius:2, boxShadow:3, p:2 }}>
                  <Typography variant="h6" sx={{ color:'#1976d2', fontWeight:'bold', mb:2 }}>
                    GPT Recommended Male Shoes
                  </Typography>
                  {gptRecommendations.gptRecommendedShoes.maleShoes.length > 0 ? (
                    <ul style={{ paddingLeft:20 }}>
                      {gptRecommendations.gptRecommendedShoes.maleShoes.map((shoe, index) => (
                        <li key={index} style={{ marginBottom:4 }}>{shoe}</li>
                      ))}
                    </ul>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      No male shoe recommendations found.
                    </Typography>
                  )}
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card variant="outlined" sx={{ borderRadius:2, boxShadow:3, p:2 }}>
                  <Typography variant="h6" sx={{ color:'#1976d2', fontWeight:'bold', mb:2 }}>
                    GPT Recommended Female Shoes
                  </Typography>
                  {gptRecommendations.gptRecommendedShoes.femaleShoes.length > 0 ? (
                    <ul style={{ paddingLeft:20 }}>
                      {gptRecommendations.gptRecommendedShoes.femaleShoes.map((shoe, index) => (
                        <li key={index} style={{ marginBottom:4 }}>{shoe}</li>
                      ))}
                    </ul>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      No female shoe recommendations found.
                    </Typography>
                  )}
                </Card>
              </Grid>
            </Grid>

            <Typography variant="h6" sx={{ mb:2, fontWeight:'bold' }}>
              Male Shoes Found: {gptRecommendations.maleShoesFound}, Female Shoes Found: {gptRecommendations.femaleShoesFound}
            </Typography>

            <Typography variant="h6" sx={{ fontWeight:'bold', mb:2 }}>
              Male Shoes from Database
            </Typography>
            {renderDbShoesSection(loadingMaleDb, maleItemData, "No male shoes found in the database for these recommendations.")}

            <Typography variant="h6" sx={{ fontWeight:'bold', mt:4, mb:2 }}>
              Female Shoes from Database
            </Typography>
            {renderDbShoesSection(loadingFemaleDb, femaleItemData, "No female shoes found in the database for these recommendations.")}
          </>
        )}
      </Container>

      <Footer />
    </Box>
  );
}