import { createSlice } from '@reduxjs/toolkit';

/**
 * cartSlice:
 * Manages items in the shopping cart.
 */
const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: []
  },
  reducers: {
    addToCart(state, action) {
      // action.payload = {id, title, price, etc.}
      state.items.push(action.payload);
    },
    clearCart(state) {
      state.items = [];
    }
  }
});

export const { addToCart, clearCart } = cartSlice.actions;
export default cartSlice.reducer;