import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import GptRecommendPage from './pages/GptRecommendPage';
import CartPage from './pages/CartPage';
import LoginPage from './pages/LoginPage'
import AIGeneratePage from './pages/AIGeneratePage';
import './App.css';
import RegisterVerifyPage from './pages/RegisterVerifyPage';
import ProfilePage from './pages/ProfilePage';

/**
 * App component defines the main routing.
 */
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gpt-recommend" element={<GptRecommendPage />} />
        <Route path="/ai-generate" element={<AIGeneratePage />} /> {/* 新增路由 */}
        <Route path="/cart" element={<CartPage />} />
        <Route path="/RegisterVerifyPage" element={<RegisterVerifyPage/>} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/profile" element={<ProfilePage />} />
      </Routes>
    </Router>
  );
}

export default App;