import { createSlice } from '@reduxjs/toolkit';

const shoesSlice = createSlice({
  name: 'shoes',
  initialState: {
    dbShoes: [],
    totalDbShoes: 0,
    gptRecommendations: null
  },
  reducers: {
    setDbShoes(state, action) {
      // action.payload: { shoes: [...], total: number }
      state.dbShoes = action.payload.shoes;
      state.totalDbShoes = action.payload.total;
    },
    setGptRecommendations(state, action) {
      state.gptRecommendations = action.payload;
    }
  }
});

export const { setDbShoes, setGptRecommendations } = shoesSlice.actions;
export default shoesSlice.reducer;