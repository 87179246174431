export const getImageUrlByGender = (genderValue) => {
  if (genderValue === "MALE") {
    return 'https://img.zcool.cn/community/011c876124e69511013eaf7092b26b.jpg';
  } else {
    return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwqiuqDCWLRsYQxOLsNg-o9oYe85qviO5ycg&s';
  }
};

export const mapShoeData = (shoes) => {
  console.log("mapShoeData input:", shoes);

  if (!Array.isArray(shoes)) {
    console.warn("mapShoeData received non-array:", shoes);
    return [];
  }

  return shoes.map((shoe) => {
    const genderValue = shoe.gender || 'FEMALE'; 
    const imgUrl = shoe.imageUrl || getImageUrlByGender(genderValue);

    const stock = shoe.stock !== undefined ? shoe.stock : 0;
    const isStockZero = stock === 0;

    const priceText = isStockZero ? 'Coming Soon' : (shoe.price ? `$${shoe.price}` : '$0');
    const ratingText = isStockZero ? '' : (shoe.rating !== undefined ? `Rating: ${shoe.rating}` : '');

    return {
      id: shoe.id || 'unknown_id',
      img: imgUrl,
      title: shoe.name || 'No name',
      author: shoe.brand || 'No brand',
      description: shoe.description || '',
      price: priceText,
      rating: ratingText,
      stock: stock,
      status: shoe.status !== undefined ? shoe.status : null
    };
  });
};