import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Divider,
  Container,
  Button,
  CircularProgress,
  ImageList,
  ImageListItem,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './HomePage.css';
import { mapShoeData } from '../utils';
import { setDbShoes } from '../store/slices/shoesSlice'; // 请根据实际路径修改
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useSelector, useDispatch } from 'react-redux';

export default function HomePage() {
  console.log("HomePage component rendered");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dbShoes = useSelector((state) => state.shoes.dbShoes);
  const totalDbShoes = useSelector((state) => state.shoes.totalDbShoes);

  console.log("From store: dbShoes:", dbShoes, "totalDbShoes:", totalDbShoes);

  const [cartItems] = useState([]);

  const [page, setPage] = useState(1);
  const pageSize = 8;
  const [loadingDb, setLoadingDb] = useState(false);

  const [sortBy, setSortBy] = useState('rating-desc'); 
  const [gender, setGender] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  const [rateOrder, setRateOrder] = useState('desc');
  const [priceOrder, setPriceOrder] = useState('desc');

  const fetchDbShoes = useCallback(async (currentPage) => {
    try {
      setLoadingDb(true);
      const params = { page: currentPage, pageSize };

      if (sortBy) params.sortBy = sortBy;
      if (gender) params.gender = gender;
      if (minPrice) params.minPrice = minPrice;
      if (maxPrice) params.maxPrice = maxPrice;

      console.log("Fetching DB shoes with params:", params);
      const response = await axios.get('http://3.107.234.91:5000/api/shoes', { params });
      console.log("Fetched shoes response:", response.data);

      const { shoes, total } = response.data;
      if (!Array.isArray(shoes)) {
        console.error("Fetched shoes data is not an array:", shoes);
      }

      // 使用Redux Toolkit的action creator更新state
      dispatch(setDbShoes({ shoes, total }));

      setLoadingDb(false);
    } catch (err) {
      console.error('Error fetching DB shoes:', err);
      setLoadingDb(false);
    }
  }, [dispatch, pageSize, sortBy, gender, minPrice, maxPrice]);

  useEffect(() => {
    console.log("useEffect triggered with deps:", {page, sortBy, gender, minPrice, maxPrice});
    fetchDbShoes(page);
  }, [fetchDbShoes, page, sortBy, gender, minPrice, maxPrice]);

  const handlePrevPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const handleNextPage = () => {
    if (page * pageSize < totalDbShoes) setPage(page + 1);
  };

  const handleViewCart = () => {
    navigate('/cart', { state: { cartItems } });
  };

  console.log("before mapShoeData, dbShoes:", dbShoes);
  let dbItemData = [];
  try {
    if (!Array.isArray(dbShoes)) {
      console.warn("dbShoes is not an array, dbShoes:", dbShoes);
    } else {
      console.log("dbShoes is array with length:", dbShoes.length);
    }
    dbItemData = mapShoeData(dbShoes);
    console.log("after mapShoeData");
  } catch (e) {
    console.error("Error in mapShoeData:", e);
    dbItemData = [];
  }

  console.log("dbShoes from store:", dbShoes);
  console.log("dbItemData:", dbItemData);

  const handleAllClick = () => {
    setSortBy('rating-desc');
    setRateOrder('desc');
    setPriceOrder('desc');
  };

  const handleNewClick = () => {
    setSortBy('created-desc');
  };

  const handleRateClick = () => {
    const newOrder = rateOrder === 'desc' ? 'asc' : 'desc';
    setRateOrder(newOrder);
    setSortBy(`rating-${newOrder}`);
  };

  const handlePriceClick = () => {
    const newOrder = priceOrder === 'desc' ? 'asc' : 'desc';
    setPriceOrder(newOrder);
    setSortBy(`price-${newOrder}`);
  };

  const handlePriceFilterApply = () => {
    setPage(1);
    fetchDbShoes(1);
  };

  return (
    <Box className="home-container">
      <Header cartItemsCount={cartItems.length} onCartClick={handleViewCart} />

      <Container maxWidth="lg" className="home-content-container">
        <Box className="home-banner">
          <Box className="home-banner-overlay">
            <Typography variant="h3" className="home-banner-text">
              Welcome to Chuan ShoeStore
            </Typography>
          </Box>
        </Box>

        <Divider className="home-divider" />

        <Typography variant="h5" className="home-section-title" sx={{ mb:2 }}>
          Database Shoes (Page {page})
        </Typography>

        <Box sx={{ display:'flex', alignItems:'center', gap:2, mb:3, flexWrap:'wrap' }}>
          <Button variant="contained" onClick={handleAllClick}>All</Button>
          <Button variant="outlined" onClick={handleNewClick}>New</Button>
          <Button variant="text" onClick={handleRateClick}>
            Rate {rateOrder === 'desc' ? '↓' : '↑'}
          </Button>
          <Button variant="text" onClick={handlePriceClick}>
            Price {priceOrder === 'desc' ? '↓' : '↑'}
          </Button>

          <FormControl size="small" sx={{ minWidth:120 }}>
            <InputLabel>Gender</InputLabel>
            <Select
              value={gender}
              label="Gender"
              onChange={(e) => { setGender(e.target.value); setPage(1); }}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </Select>
          </FormControl>

          <TextField
            size="small"
            label="Min Price"
            type="number"
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.value)}
            sx={{ width:100 }}
          />
          <TextField
            size="small"
            label="Max Price"
            type="number"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
            sx={{ width:100 }}
          />
          <Button variant="contained" onClick={handlePriceFilterApply}>Apply</Button>
        </Box>

        {loadingDb ? (
          <Box className="home-loading-container">
            <CircularProgress/>
          </Box>
        ) : (
          <>
            <ImageList cols={4} gap={20} className="home-image-list" rowHeight="auto">
              {dbItemData.map((item) => (
                <ImageListItem
                  key={item.id}
                  className="home-shoe-item"
                  onClick={() => navigate(`/product/${item.id}`)}
                >
                  <img
                    src={item.img}
                    alt={item.title}
                    loading="lazy"
                    className="home-shoe-img"
                  />
                  <Box className="home-shoe-info">
                    <Typography variant="subtitle1" className="home-shoe-title">
                      {item.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" className="home-shoe-author">
                      {item.author}
                    </Typography>
                    <Typography variant="h6" className="home-shoe-price">
                      {item.price}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" className="home-shoe-rating">
                      {item.rating}
                    </Typography>
                  </Box>
                </ImageListItem>
              ))}
            </ImageList>
            <Box className="home-pagination">
              <Button onClick={handlePrevPage} disabled={page===1}>Previous</Button>
              <Button onClick={handleNextPage} disabled={page * pageSize >= totalDbShoes}>Next</Button>
            </Box>
          </>
        )}
      </Container>

      <Footer />
    </Box>
  );
}