import React from 'react';
import { Box, Container, Grid, Typography, Divider } from '@mui/material';
import './Footer.css';

/**
 * Footer component reused across pages.
 */
export default function Footer() {
  return (
    <Box className="footer-box">
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className="footer-title">
              About Us
            </Typography>
            <Typography variant="body2" className="footer-text">
              ShoeStore is a professional shoe e-commerce platform dedicated to providing the latest fashionable shoes.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className="footer-title">
              Contact Us
            </Typography>
            <Typography variant="body2" className="footer-text">Email: support@shoestore.com</Typography>
            <Typography variant="body2" className="footer-text">Phone: 123-456-7890</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className="footer-title">
              Follow Us
            </Typography>
            <Typography variant="body2" className="footer-text">Twitter: @ShoeStore</Typography>
            <Typography variant="body2" className="footer-text">Instagram: @ShoeStore</Typography>
          </Grid>
        </Grid>
        <Divider className="footer-divider" />
        <Typography variant="body2" align="center" className="footer-text">
          © 2025 ShoeStore. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}